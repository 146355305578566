<template>
  <f7-page name="home">
    <!-- Top Navbar -->
    <f7-navbar>
      <f7-nav-title-large>  {{ headerTitle }} </f7-nav-title-large>
    </f7-navbar>
    <!-- Page content-->
    <form
    novalidate
    @submit="submit"
    >
    <f7-block strong :innerHTML="headerDescription">
      
      <div >ลูกค้าแจ้งการโอนเงินโดยการระบุข้อมูลตามด้านล่างให้ถูกต้อง</div>
    </f7-block> 
 
    <f7-block-title>แบบฟอร์มแจ้งโอนเงิน</f7-block-title>

<f7-card >
    
    <f7-card-content>
      <f7-list>
        <f7-list-input
          label="User ของคุณ"
          type="text"
          v-model:value="form.customerRefUser"
          placeholder="กรอกเฉพาะ user ของคุณ"
        ></f7-list-input>
        <!-- <f7-list-item>
          <f7-list-input
            label="วันที่โอน"
            type="date"
            placeholder="ระบุให้ถูกต้องเพื่อความรวดเร็วในการตรวจสอบ"
            v-model:value="transferDate"
          ></f7-list-input>
         
          <f7-list-input
            label="เวลาที่โอน"
            type="time"
            placeholder="ระบุให้ถูกต้องเพื่อความรวดเร็วในการตรวจสอบ"
            v-model:value="transferTime"
          ></f7-list-input>

        </f7-list-item> -->
        <f7-list-input
            label="วันเวลาที่โอนตามใบสลิป"
            type="datetime-local"
            placeholder="ระบุให้ถูกต้องเพื่อความรวดเร็วในการตรวจสอบ"
            v-model:value="inputTransferDateTime"
          ></f7-list-input>
        <f7-list-input
          label="จำนวนเงิน"
          type="number"
          v-model:value="form.transferAmount"
          placeholder="ตัวเลข"
          @change="ParseInteger"
        ></f7-list-input>
        <f7-list-input
          label="รหัสลับ"
          type="password"
          v-model:value="form.passmove"
          placeholder="*****"
        ></f7-list-input>
        
        <div style="padding-left: 5%; padding-right: 5%;">
           
          <img :src="imageData" v-if="imageData" style="width: 100%;" />
           <img src="static/imgs/bill.png" v-if="!imageData" style="width: 100%;" />
        </div> 
        <f7-list-item>
          <div class="item-title" style="font-size: var(--f7-label-font-size)">แนบภาพสลิป</div>
          <input  type="file"
            accept="image/*"
            @change="onFileChange" />
        </f7-list-item>
      </f7-list>
    </f7-card-content>
    
  </f7-card>
 

    <f7-block-title>ตรวจสอบข้อมูลของคุณก่อนทำการยืนยันข้อมูล</f7-block-title>
    <f7-block strong>
        <div v-if="errorMsg && errorMsg !== ''"> {{ errorMsg }} </div>
        <f7-button :disabled="isSubmit" preloader :loading="isSubmit" type="submit" class="col" large fill raised color="green">ยืนยันข้อมูล</f7-button>
      
    </f7-block>
    </form>

  </f7-page>
</template>
<script>
import { ref, onMounted } from 'vue';
import { f7, f7ready } from 'framework7-vue';
import bank from '../config/bank.json';
import ownAccount from '../config/own.json';
import store from '../js/store';
import ApiService from "../utils/api.service";
import settings from '../config/settings';


export default {
  data(){
    return {
      bank: bank,
      ownAccount: ownAccount,
      form: {},
      inputTransferDateTime: this.currentDateTimeLocalInput(),
      transferDate: this.currentDateLocalInput(),
      transferTime: this.currentTimeLocalInput(),
      companyName: "",
      headerTitle: "",
      headerDescription: "",
      formTitle: "",
      imageData: null,
      isSubmit: false,
      linkCheckHistoryTransfer: "",
      messageResponseEnd: "",
      errorMsg: "",
    }
  },
  setup() {
    
     
      onMounted(() => {
        f7ready(() => {
          f7.dialog.preloader();
        });
      });

  },
  mounted(){
            ApiService.get("transfer-form-template").then(res => {
            f7.dialog.close();
            this.companyName = res.data.companyName ? res.data.companyName : "";
            this.headerTitle = res.data.headerTitle ? res.data.headerTitle : "";
            this.headerDescription = res.data.headerDescription ? res.data.headerDescription : "";
            this.formTitle = res.data.formTitle ? res.data.formTitle : "";
            this.linkCheckHistoryTransfer = res.data.linkCheckHistoryTransfer ? res.data.linkCheckHistoryTransfer : "";
            this.messageResponseEnd = res.data.messageResponseEnd ? res.data.messageResponseEnd : "";
             });

            if(settings.useLineLiff){
              window.liff.ready.then(() => {
              // do something you want when liff.init finishes
                window.liff.getProfile().then((res) => {

                  // this.objectTest = JSON.stringify(res)
                  this.form.lineId = res && res.userId ? res.userId : null;
                  this.form.lineData = res;
                  this.form.lineData.email  = liff.getDecodedIDToken().email;
                  store.dispatch('setLine', res)
                })
              })
            }
             

  },
  methods:{
    ParseInteger(e){
      // console.log(e.target.value)
      if(parseInt(e.target.value) < 20){
        f7.dialog.alert("ขั้นต่ำการเติมเงิน 20 บาทขึ้นไปค่ะ")
        this.form.transferAmount = "20"
      }else{
        this.form.transferAmount = parseInt(e.target.value)
      }
      
      
    },
    currentDateTimeLocalInput() {
      const now = new Date();
      now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
      return now.toISOString().slice(0,16);
    },
    currentDateLocalInput() {
      const now = new Date();
      now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
      // console.log(now.toISOString().slice(0,10))
      return now.toISOString().slice(0,10);
    },
    currentTimeLocalInput() {
      const now = new Date();
      now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
      // console.log(now.toISOString(), now.toISOString().slice(11,16))
      return now.toISOString().slice(11,16);
    },
    
    submitDev(e){
      e.preventDefault();
      // console.log(this.transferDate, this.transferTime, new Date(`${this.transferDate} ${this.transferTime}`));
     // console.log(new Date(this.inputTransferDateTime))
     if(!this.transferDate || !this.transferTime){
        f7.dialog.alert("กรุณาระบุวัน/เวลาโอนเงิน")
      }else{
        this.form.transferDateTime = new Date(parseInt(this.transferDate.split('-')[0]), parseInt(this.transferDate.split('-')[1])-1 ,parseInt(this.transferDate.split('-')[2]), parseInt(this.transferTime.split(":")[0]), parseInt(this.transferTime.split(":")[1]), 0, 0)
      }

      // Development
    //    ApiService.post("transfers", this.form).then(r => {
              
    //           if(r.status === 200){
    //             f7.dialog.alert("บันทึกข้อมูลสำเร็จ กรุณารอระบบตรวจสอบสักครู่นะคะ") 
    //             if(settings.useLineLiff){
    //               setTimeout(function(){ window.liff.closeWindow(); }, 3000)
    //             }
    //           }else{
    //             f7.dialog.alert("ไม่สามารถบันทึกข้อมูลได้ กรุณาลองใหม่อีกครั้ง")
    //             this.isSubmit = false;
    //           }
    //         });
    },
    submit(e){
      e.preventDefault();
      // console.log(this.form);

      this.isSubmit = true;
      
      if(!this.form.customerRefUser){
        f7.dialog.alert("กรุณาระบุ Username ของคุณ")
      }else{
        this.form.customerRefUser = this.form.customerRefUser ? this.form.customerRefUser.trim() : '';
      }

      // if(!this.form.transferDateTime){
      //   f7.dialog.alert("กรุณาระบุวันเวลาโอนเงิน")
      // }

      // if(!this.form.transferAmount){
      //   f7.dialog.alert("กรุณากรอกจำนวนเงิน")
      // }

      if(!this.form.fileUpload){
        f7.dialog.alert("กรุณาอัปโหลดไฟล์สลิปก่อนยืนยัน")
      }

      // แยกช่อง
      // if(!this.transferDate || !this.transferTime){
      //   f7.dialog.alert("กรุณาระบุวัน/เวลาโอนเงิน")
      // }else{
      //   this.form.transferDateTime = new Date(parseInt(this.transferDate.split('-')[0]), parseInt(this.transferDate.split('-')[1])-1 ,parseInt(this.transferDate.split('-')[2]), parseInt(this.transferTime.split(":")[0]), parseInt(this.transferTime.split(":")[1]), 0, 0)
      // }

      // ช่องเดียว
      if(!this.inputTransferDateTime){
        f7.dialog.alert("กรุณาระบุวัน/เวลาโอนเงิน")
      }else{
        
        const dataSpilt = this.inputTransferDateTime.split('T')
        const date = dataSpilt[0].split('-')
        const time = dataSpilt[1].split(':')

        this.form.transferDateTime = new Date(parseInt(date[0]), parseInt(date[1])-1 ,parseInt(date[2]), parseInt(time[0]), parseInt(time[1]), 0, 0)
        
      }

      if(this.form.fileUpload && this.form.fileUpload.length > 0 && this.form.fileUpload[0].size > settings.uploadMaxsize){
        f7.dialog.alert("ขนาดไฟล์สลิปเงินของคุณใหญ่เกินกำหนด กรุณาลดขนาดไฟล์")
      }

      if(!this.form.passmove){
        f7.dialog.alert("กรุณากรอกรหัสลับ")
      }
    

      
      if(this.form.customerRefUser && this.form.transferDateTime && this.form.transferAmount && this.form.passmove && this.form.fileUpload && this.form.fileUpload.length > 0 && this.form.fileUpload[0].size < settings.uploadMaxsize){
       ApiService.query("transfers", `_sort=created_at:desc&_limit=1${this.form.lineId ? `&lineId=${this.form.lineId}` : ''}`).then(res => {
         if(res && res.data.length === 1){
           const CurrentDate = new Date()
           const latestTransfer = new Date(res.data[0].created_at)
           const difference = (CurrentDate - latestTransfer) / 1000;
          //  console.log("res.data[0]", res.data[0])
          //  console.log("CURENT", CurrentDate)
          //  console.log("latestTransfer", latestTransfer)
          //  console.log(difference, " sec.")
          
           return res.data[0].status === 'rejected' ? 300 : difference // ถ้าเป็นสถานะ Rejected สามารถแจ้งได้เลย
         } else {
           return 300
         }
       }).then(r => {
         if(r < 300){ // เร็วไป
            f7.dialog.alert("ไม่สามารถจัดส่งข้อมูลได้ เพราะคุณส่งข้อมูลเร็วเกินกำหนด")
            this.errorMsg = `คุณส่งข้อมูลใหม่ เร็วเกินไป กรุณาลองใหม่ ในอีก ${(300 - r).toFixed(2)} วินาที`
            this.isSubmit = false;
         }else{
           // Insert
            ApiService.upload(this.form.fileUpload).then(res => {
              return res.status === 200 ? res.data : null
            }).then(res => {

              if(res.length > 0){
                this.form.fileUpload = res;
                ApiService.post("transfers", this.form).then(r => {
                  
                  if(r.status === 200){
                    f7.dialog.alert("บันทึกข้อมูลสำเร็จ กรุณารอระบบตรวจสอบสักครู่นะคะ") 
                    if(settings.useLineLiff){
                        if(this.messageResponseEnd !== ""){
                          liff.sendMessages([
                            {
                              type: 'text',
                              text: this.messageResponseEnd
                            }
                          ]).then(() => {
                              console.log('message sent');
                            })
                            .catch((err) => {
                              console.log('error', err);
                            });
                        }
                        if(this.linkCheckHistoryTransfer !== ""){
                          liff.sendMessages([
                            {
                              type: 'text',
                              text: this.linkCheckHistoryTransfer
                            }
                          ]).then(() => {
                              console.log('message sent');
                            })
                            .catch((err) => {
                              console.log('error', err);
                            });
                        }
                      

                      setTimeout(function(){ window.liff.closeWindow(); }, 3000)
                    }
                  }else{
                    f7.dialog.alert("ไม่สามารถบันทึกข้อมูลได้ กรุณาลองใหม่อีกครั้ง")
                    this.errorMsg = JSON.stringify(this.form)
                    this.isSubmit = false;
                  }
                });
              }else{
                f7.dialog.alert("ไม่พบไฟล์อัปโหลดกรุณาลองใหม่อีกครั้ง")
                this.errorMsg = JSON.stringify(res)
                this.isSubmit = false;
              }
              
            })
            // End: Insert
         } 

         console.log("FFF: ", r)
       })
       
      }else{
        this.isSubmit = false;
      }
      

    },
    onFileChange(event) {
      
        // Reference to the DOM input element
        var input = event.target;
        // Ensure that you have a file before attempting to read it
        if (input.files && input.files[0]) {
            // create a new FileReader to read this image and convert to base64 format
            var reader = new FileReader();
            // Define a callback function to run, when FileReader finishes its job
            reader.onload = (e) => {
                // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
                // Read image as base64 and set to imageData
                this.imageData = e.target.result;
            }
            // Start the reader job - read file as a data url (base64 format)
            reader.readAsDataURL(input.files[0]);
            this.form.fileUpload = input.files
        }
    }
  }
}
</script>
<style scoped>
.dateTimeInput {
    max-width: 150vw !important; 
}
</style>